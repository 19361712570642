import { protectedResources } from "../authConfig";
import { msalInstance } from "../msalInstance";

async function fetchToken() {
  const currentAccount = msalInstance.getActiveAccount();
  const accessTokenRequest = {
    scopes: protectedResources.management.scopes.read,
    account: currentAccount,
  };
  if (currentAccount) {
    // @ts-ignore
    return await msalInstance.acquireTokenSilent(accessTokenRequest);
  }
  return null;
}

const getToken = async () => {
  const token = await fetchToken();
  console.log();
  try {
    return token;
  } catch (error) {
    Object.keys(window.localStorage).forEach(function (key) {
      if (key.startsWith("msal")) {
        window.localStorage.removeItem(key);
      }
    });
  }
};

export default getToken;
