import { useMutation, useQuery } from "@tanstack/react-query";
import dispatcher from "./dispatcher";

const httpClient = {
  Get: (url: string, queryKeys: any[], params: {}, options: any) =>
    useQuery(
      queryKeys,
      async (): Promise<any> => {
        try {
          return await dispatcher.get(url, { searchParams: params }).json();
        } catch (error) {

          return Promise.reject(error);
        }
      },
      { ...options, refetchOnWindowFocus: false }
    ),

  Post: (url: string, queryKeys: any[], options: any, headers: any) =>
    useMutation(
      queryKeys,
      async (body: any) => {
        try {
          return await dispatcher
            .post(url, { body: body, headers: headers })
            .json();
        } catch (error) {
          return Promise.reject(error);
        }
      },
      { ...options, refetchOnWindowFocus: false }
    ),

  Put: (url: string, queryKeys: any[], options: any, headers: any) =>
    useMutation(
      queryKeys,
      async (body: any) => {
        try {
          return await dispatcher
            .put(url, { body: body, headers: headers })
            .json();
        } catch (error) {
          return Promise.reject(error);
        }
      },
      { ...options, refetchOnWindowFocus: false }
    ),

  Delete: (url: string, queryKeys: any[], options: any, headers: any) =>
    useMutation(
      queryKeys,
      async (body: any) => {
        try {
          return await dispatcher.delete(url, { body: body, headers: headers });
        } catch (e) {
          return Promise.reject(e);
        }
      },
      { ...options, refetchOnWindowFocus: false }
    ),
};

export default httpClient;
