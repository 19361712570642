import {IconButton} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import "./sideBarButton.scss"
import {IconButtonInterface} from "../sideBar/SideBar"
import {UIStoreContext} from "../../context";
import {observer} from "mobx-react-lite";
import { useNavigate } from "react-router-dom";


interface SidebarButtonInterface {
  icon: IconButtonInterface;
}

const SidebarButton = ({icon}: SidebarButtonInterface) => {
  const uiStore = useContext(UIStoreContext);
  const navigate = useNavigate()


  const onClick = () => {
    uiStore.setCurrentPage(icon.text)
    navigate(icon.url)
  }


  return (
    <div className={`sidebarIcon ${window.location.pathname === "/" + icon.url ? "sidebarIconSelected" : ""}`}>
      <IconButton onClick={onClick}>
        <img src={window.location.pathname === "/" + icon.url ? icon.selectedIcon : icon.icon} alt={icon.text}/>
        <span style={{color: window.location.pathname === "/" + icon.url ? "white": ""}}>{icon.text}</span>
      </IconButton>
    </div>
  )
}

export default observer(SidebarButton)