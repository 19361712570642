import * as React from 'react';
import DeviceList from "../../components/deviceList/deviceList";

const DeviceManagement = () => {
  return (
    <div>
      <DeviceList/>
    </div>
)
}

export default DeviceManagement