import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {observer} from "mobx-react-lite";
import DateRangeFilter from "../dateRangeFilter/DateRangeFilter";
import FilterSelectMenu from "../filterSelectMenu/FilterSelectMenu";
import AvailableSystems from "../../client-api/services/availableSystems";
import {useContext, useState} from "react";
import {UIStoreContext} from "../../context";
import "./filter.scss";
import {Button, CircularProgress, Divider} from "@mui/material";
import {useLocation} from "react-router-dom";
import {FilterProps} from "../../common/filterProps";
import {blue} from "@mui/material/colors";
import {DateTime} from "luxon";
import deviceManagementService from "../../client-api/services/deviceManagement";

const classes = [
    "All",
    "Animal",
    //"Breaking Shoe",
    "Car",
    "Locomotive",
    "Obstacle",
    "Person",
    //"Truck"
];

const Filter = () => {
    const uiStore = useContext(UIStoreContext);
    const location = useLocation();
    const [filterTempState, setFilterTempState] = useState<FilterProps>(
        uiStore.getFilter()
    );

    const setTempSystem = (value: string) => {
        setFilterTempState({...filterTempState, selectedSystem: value});
    };

    const setTempClass = (value: string) => {
        setFilterTempState({...filterTempState, selectedClass: value});
    };

    const setTempDate = (newDateObj: {
        startDate: DateTime;
        endDate: DateTime;
        key: string;
        label: string;
    }) => {
        setFilterTempState({...filterTempState, dateFilter: newDateObj});
    };

    const setTempLocation = (value: string) => {
        setFilterTempState({...filterTempState, location: value});
    };

    const setTempFilter = (value: string) => {
        setFilterTempState({...filterTempState, video_source: value});
    };

    const searchButtonOnClick = () => {
        const dateNowStartOfDay = DateTime.now().startOf("day");
        const selectedDateStartOfDay =
            filterTempState.dateFilter.endDate.startOf("day");
        const d = selectedDateStartOfDay.diff(dateNowStartOfDay).as("seconds");
        //uiStore.setFilter(filterTempState);
        //debugger;
        if (d === 0) {
            uiStore.setFilter({
                ...filterTempState,
                dateFilter: {...filterTempState.dateFilter, endDate: DateTime.now()},
            });
        } else {
            uiStore.setFilter(filterTempState);
        }
    };

    return (
        <div data-testid={"filter"} className={"filterWrapper"}>

            {["/cropsDownload"].includes(location.pathname) || (
                <>
                    <DateRangeFilter
                        date={filterTempState.dateFilter}
                        setDate={(newDate: {
                            startDate: DateTime;
                            endDate: DateTime;
                            key: string;
                            label: string;
                        }) => setTempDate(newDate)}
                    />
                    <Divider orientation="vertical" flexItem/>
                </>
            )}
            <FilterSelectMenu
                required={true}
                value={filterTempState.selectedSystem}
                values={(deviceManagementService.GetDevices().data.map((device: any) => device.tags ? {
                    key: device.id,
                    value: device.tags.loco_name
                } : {key: device.id, value: device.id}))}
                setValue={(event: string) => setTempSystem(event)}
                header={"Systems"}
            />
            <Divider orientation="vertical" flexItem/>
            {!["/performanceReport"].includes(location.pathname) || (
                <>
                    <FilterSelectMenu
                        disabled={location.pathname !== "/performanceReport"}
                        value={filterTempState.selectedClass}
                        values={classes}
                        setValue={(event: any) => setTempClass(event)}
                        header={"Classification"}
                    />

                </>
            )}
            {!["/videos"].includes(location.pathname) || (
                <>
                    <FilterSelectMenu
                        disabled={location.pathname !== "/videos"}
                        value={filterTempState.video_source}
                        values={["All", "VIS", "TIR"]}
                        setValue={(event: string) => setTempFilter(event)}
                        header={"Crops Source"}
                    />
                </>
            )}
            <Divider orientation="vertical" flexItem/>
            <Button
                variant={"contained"}
                className={"applyButton"}
                onClick={searchButtonOnClick}
            >
                Search
                {uiStore.filterApplyButtonDisabled && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: blue[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Button>
            {/*<Button className={"restButton"} onClick={() => {uiStore.setFilter(DEFAULT_FILTER); setFilterTempState(DEFAULT_FILTER)}}>Reset</Button>*/}
        </div>
    );
};

export default observer(Filter);
