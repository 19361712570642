import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useState} from "react";

export interface FilterSelectMenuProps {
  value: string
  values: string[]
  setValue: any
  header: string
  disabled?: boolean
  required?: boolean
}

const FilterSelectMenu = ({value, values, setValue, header, disabled = false, required = false}: FilterSelectMenuProps) => {
  const [selectOpen, setSelectIsOpen] = useState(false)
  const changeEvent = (event: SelectChangeEvent) => {
    if (event.target){
      setValue(event.target.value)
    }
  }

  return (
    <Box data-testid={"filter-select-menu-box"} sx={{ minWidth: 120 }}>
      <FormControl required={required} fullWidth variant="standard">
        <InputLabel style={selectOpen || value ? {marginTop: "4px"} : {}} variant="standard">
          {header}
        </InputLabel>
        <Select
          data-testid={"filter-values-select"}
          onOpen={() => setSelectIsOpen(true)} onClose={() => setSelectIsOpen(false)} disabled={disabled} value={value} onChange={changeEvent} disableUnderline={true}>
          {values.map((val) => <MenuItem value={ (typeof val == "object") ? val['key']: val }>{(typeof val == "object") ? val['value']: val}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  )
}

export default observer(FilterSelectMenu);