import { Drawer } from "@mui/material";
import React from "react";
import styles from "./SideBar.module.scss";
import logo from "./rail-vision-logo-small.png";
import cameraIcon from "./icons/camera.png";
import cameraIconSelected from "./icons/camera_selected.png";
import mapIcon from "./icons/map.png";
import mapIconSelected from "./icons/map_selected.png";
import usageIcon from "./icons/usage.png";
import usageIconSelected from "./icons/usage_selected.png";
import trainIcon from "./icons/train.png";
import trainIconSelected from "./icons/train_selected.png";
import SidebarButton from "../sidebarButton/SidebarButton";
import { observer } from "mobx-react-lite";
import userService from "../../client-api/services/user";
import { UserRole } from "../../common/userRole";
import packageJson from "../../../package.json";

export interface IconButtonInterface {
  icon: any;
  selectedIcon: any;
  text: string;
  url: string;
}

const SideBar = () => {
  const userRole = userService.GetUserRole();
  const role = userRole.data ? userRole.data.role : UserRole.user;

  const icons: IconButtonInterface[] = [
    {
      icon: trainIcon,
      selectedIcon: trainIconSelected,
      text: "Devices",
      url: "devices",
    },
    {
      icon: trainIcon,
      selectedIcon: trainIconSelected,
      text: "Events Report",
      url: "performanceReport",
    },
    {
      icon: mapIcon,
      selectedIcon: mapIconSelected,
      text: "Train Route",
      url: "trainRoute",
    },
    ...(role === UserRole.super_user
      ? [
          {
            icon: cameraIcon,
            selectedIcon: cameraIconSelected,
            text: "Management",
            url: "management",
          },
        ]
      : []),

    {
      icon: usageIcon,
      selectedIcon: usageIconSelected,
      text: "System Usage",
      url: "systemUsage",
    },
  ];

  return (
    <Drawer variant="permanent" anchor="left">
      <div className={`${styles.MuiDrawerPaper} ${styles.sidebarWrapper}`}>
        <div className={`${styles.RVIcon} row`}>
          <img className={styles.Img} src={logo} alt={"Rail Vision"} />
        </div>
        <div
          data-testid="sidebarIcons"
          className={`${styles.iconsWrapper} row`}
        >
          {icons.map((icon) => {
            return <SidebarButton icon={icon} />;
          })}
        </div>
        <span className={styles.version}>version: {packageJson.version}</span>
      </div>
    </Drawer>
  );
};

export default observer(SideBar);
