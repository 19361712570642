import { useContext } from "react";
import client from "../httpClient";
import { UIStoreContext } from "../../context";

const cropsDownload = {
  GetCropsNamesByTime: () => {
    const uiStore = useContext(UIStoreContext);
    return client.Get(
      "detectionCropsByTime",
      [
        "detectionCropsByTime",
        uiStore.getEndDate(),
        uiStore.getStartDate(),
        uiStore.getSelectedSystem(),
      ],
      {
        start_date: uiStore.getStartDate(),
        end_date: uiStore.getEndDate(),
        system_id: uiStore.getSelectedSystem(),
      },
      { placeholderData: [], enabled: uiStore.getSelectedSystem() !== "" }
    );
  },
  GetCropsByName: () => {
    const uiStore = useContext(UIStoreContext);
    return client.Post(
      "detectionCropsByName",
      ["detectionCropsByName"],
      { enabled: uiStore.getSelectedSystem() !== "" },
      { csrf_token: uiStore.getCsrfToken() }
    );
  },
};

export default cropsDownload;
