import { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { DateTime } from "luxon";
import "./dateRangeFilter.scss";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";

interface DateRangeFilterProps {
  date: { startDate: DateTime; endDate: DateTime; key: string; label: string };
  setDate: (newDate: {
    startDate: DateTime;
    endDate: DateTime;
    key: string;
    label: string;
  }) => void;
}

const DateRangeFilter = ({ date, setDate }: DateRangeFilterProps) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [startTime, setStartTime] = useState<Dayjs | null>(
    dayjs("2022-04-17T00:00")
  );
  const [endTime, setEndTime] = useState<Dayjs | null>(
    dayjs("2022-04-17T23:59")
  );
  return (
    <div>
      <FormControl
        data-testid={"date-filter"}
        required={true}
        fullWidth
        variant="standard"
      >
        <InputLabel style={{ marginTop: "4px" }} variant="standard">
          Date
        </InputLabel>
        <Select value={date.label} disableUnderline={true} required={true}>
          <MenuItem disabled value="">
            <em>Date Range</em>
          </MenuItem>
          <MenuItem
            value={"Custom Date Range"}
            onClick={() => setOpenPopover(true)}
          >
            Custom Date Range
          </MenuItem>
          <MenuItem
            data-testid={"date-filter-today"}
            value={"Today"}
            onClick={() =>
              setDate({
                ...date,
                label: "Today",
                startDate: DateTime.now().startOf("day"),
                endDate: DateTime.now(),
              })
            }
          >
            Today
          </MenuItem>
          <MenuItem
            data-testid={"date-filter-yesterday"}
            value={"Yesterday"}
            onClick={() =>
              setDate({
                ...date,
                label: "Yesterday",
                startDate: DateTime.now().minus({ days: 1 }).startOf("day"),
                endDate: DateTime.now().minus({ days: 1 }).endOf("day"),
              })
            }
          >
            Yesterday
          </MenuItem>
          <MenuItem
            data-testid={"date-filter-last3"}
            value={"Last 3 Days"}
            onClick={() =>
              setDate({
                ...date,
                label: "Last 3 Days",
                startDate: DateTime.now().startOf("day").minus({ days: 3 }),
                endDate: DateTime.now(),
              })
            }
          >
            Last 3 Days
          </MenuItem>
          <MenuItem
            data-testid={"date-filter-last7"}
            value={"Last 7 Days"}
            onClick={() =>
              setDate({
                ...date,
                label: "Last 7 Days",
                startDate: DateTime.now().startOf("day").minus({ days: 7 }),
                endDate: DateTime.now(),
              })
            }
          >
            Last 7 Days
          </MenuItem>
        </Select>
      </FormControl>
      <Popover
        open={openPopover}
        onClose={() => setOpenPopover(false)}
        anchorReference={"none"}
        className={"popoverRoot"}
      >
        <div className={"dateRangeWrapper"}>
          <DateRange
            onChange={(item) => {
              setDate({
                ...date,
                startDate: DateTime.fromJSDate(
                  item.selection.startDate as Date
                ),
                endDate: DateTime.fromJSDate(
                  item.selection.endDate as Date
                ).endOf("day"),
                label: "Custom Date Range",
              });
              setStartTime(dayjs("2022-04-17T00:00"));
              setEndTime(dayjs("2022-04-17T23:59"));
            }}
            moveRangeOnFirstSelection={false}
            months={2}
            minDate={addDays(new Date(), -600)}
            maxDate={new Date()}
            direction={"horizontal"}
            scroll={{ enabled: false, calendarHeight: 100, calendarWidth:400 }}
            ranges={[
              {
                startDate: date.startDate.toJSDate(),
                endDate: date.endDate.toJSDate(),
                key: "selection",
              },
            ]}
          />
          <div className="timeContainer">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label={"from hh:mm"}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
                value={startTime}
                onChange={(newValue) => {
                  setStartTime(newValue);
                  setDate({
                    ...date,
                    startDate: date.startDate.set({
                      hour: newValue?.hour(),
                      minute: newValue?.minute(),
                    }),
                  });
                }}
              />
              <TimePicker
                label={"to hh:mm"}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
                value={endTime}
                onChange={(newValue) => {
                  setEndTime(newValue);
                  setDate({
                    ...date,
                    endDate: date.endDate.set({
                      hour: newValue?.hour(),
                      minute: newValue?.minute(),
                    }),
                  });
                }}
              />
            </LocalizationProvider>
          </div>
          <Button variant={"contained"} onClick={() => setOpenPopover(false)}>
            Close
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default observer(DateRangeFilter);
