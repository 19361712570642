import client from "../httpClient";

const deviceManagementService = {
  AddDeviceTags: () => {
    return client.Put("fleetManagement", ["fleetManagement"], {}, {});
  },
  GetDevices: () => {
    const response = client.Get(
      "fleetManagement",
      ["fleetManagement"],
      {},
      { staleTime: 6000 * 30 , cacheTime: 0}
    );
    if (response.isSuccess) {
      return {
        ...response,
        data: response.data.map((data: any, id: number) => {

          return {
            status2: (data.status === 'enabled')? true: false,
            ...data,
          };
        }),
      };
    } else {
      return { ...response, data: [] };
    }
  },
};

export default deviceManagementService;
