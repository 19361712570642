import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useMsal } from "@azure/msal-react";
import InvalidateSessionService from "../../client-api/services/invalidateSession";

export default function AvatarMenu({ open, anchorEl, handleClose }: any) {
  const { refetch } = InvalidateSessionService.InvalidateSession();

  const { instance } = useMsal();

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={async () => {
            await refetch();
            instance.logoutRedirect();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
