import { AppBar, Avatar, IconButton, Toolbar, Typography } from "@mui/material";
import "./navBar.scss";
import React, { useContext, useEffect, useState } from "react";
import { UIStoreContext } from "../../context";
import { observer } from "mobx-react-lite";
import { pagesTransMap } from "../../common/pagesTransMap";
import getToken from "../../hooks/getToken";
import { DateTime } from "luxon";
import { useMsal } from "@azure/msal-react";
import AvatarMenu from "../avatarMenu/avatarMenu";

const Navbar = () => {
  const uiStore = useContext(UIStoreContext);
  const [name, setName] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const { instance, inProgress } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    uiStore.setCurrentPage(pagesTransMap[window.location.pathname]);
  }, [uiStore]);

  useEffect(() => {
    getToken().then((token) => {
      if (token) {
        setName(
          // @ts-ignore
          token.idTokenClaims.given_name.charAt(0).toUpperCase() +
            // @ts-ignore
            token.idTokenClaims.family_name.charAt(0).toUpperCase()
        );
      }
    });
  }, []);

  useEffect(() => {
    console.log("setting interval");
    const interval = setInterval(() => {
      if (uiStore.getLastUpdate() !== undefined) {
        const minutesAgo = Math.abs(
          Math.floor(uiStore.lastUpdate?.diffNow("minutes").minutes || 0)
        );
        const hoursAgo = Math.abs(
          Math.round((uiStore.lastUpdate?.diffNow("hours").hours || 0) * 2) / 2
        );
        const elapsed: string =
          minutesAgo > 60 ? hoursAgo + " hours" : minutesAgo + " min.";
        // @ts-ignore
        const time = uiStore
          .getLastUpdate()
          .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
        setLastUpdated(`Last updated ${elapsed} ago - ${time}`);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [uiStore]);

  return (
    <AppBar
      className="navbar"
      elevation={0}
      color="transparent"
      position="static"
    >
      <Toolbar>
        <h1>{uiStore.getCurrentPage()}</h1>
        <Typography
          sx={{
            flexGrow: 1,
            paddingTop: "10px",
            marginLeft: "10px",
            color: "gray",
          }}
        >
          {uiStore.getLastUpdate() ? lastUpdated : ""}
        </Typography>
        <div
          className="navbarLeft"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar onClick={handleClick}>{name}</Avatar>
          <AvatarMenu
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default observer(Navbar);
