import ky from "ky";
import getToken from "../hooks/getToken";
import uiStore from "../store/UiStore";
import { DateTime } from "luxon";

const dispatcher = ky.extend({
  timeout: 60000,
  credentials: "include",
  hooks: {
    beforeRequest: [
      async (request: Request) => {
        const token = await getToken();
        if (token) {
          request.headers.set("Authorization", "Bearer " + token.accessToken);
        } else {
          return new Response("No token", { status: 401 });
        }
      },
    ],
    afterResponse: [
      (request, options, response) => {
        if (response.status === 200 || response.status === 201) {
          if (
            !["userRole", "availableSystems"].some((v) =>
              request.url.includes(v)
            )
          ) {
            uiStore.setLastUpdate(DateTime.now());
          }
        }
      },
    ],
  },
});

export default dispatcher;
