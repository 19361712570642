import { Outlet } from "react-router-dom";
import SideBar from "../sideBar/SideBar";
import "./AppLayout.scss";
import React, { useEffect } from "react";
import NavBar from "../navBar/Navbar";
import Filter from "../filter/Filter";
import { observer } from "mobx-react-lite";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const AppLayout = ({ filter = true }) => {
  // const { instance } = useMsal();
  // useEffect(() => {
  //   instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  // }, []);

  return (
    <AuthenticatedTemplate>
      <div data-testid={"app-layout"} className="layout">
        <SideBar />
        <div className="outlet">
          <div>
            <NavBar />
          </div>
          {filter && (
            <div>
              <Filter />
            </div>
          )}
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </AuthenticatedTemplate>
  );
};

export default observer(AppLayout);
