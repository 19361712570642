import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "./NoDataToShow.module.scss";
import {Stack, Typography} from "@mui/material";
import r from "./rv-logo-gray/r.svg";
import v from "./rv-logo-gray/v.svg";
import React from "react";

const NoDataToShow = () => {
  return (
    <TableRow><TableCell colSpan={7}>
      <div>
        <Stack alignItems={"center"}>
          <div>
            <img src={r} alt={"rail"}/>
            <img src={v} alt={"vision"}/>
          </div>
          <Typography fontSize={"20px"} style={{textShadow: "0px 1px 3px"}} color={"#51548D"} textAlign={"center"}>
              In order to see results please select at least <br/>
              a <b>Time Period</b> and a <b>System</b>
          </Typography>
        </Stack>


      </div>
    </TableCell></TableRow>
  )
}

export default NoDataToShow