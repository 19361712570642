import { observer } from "mobx-react-lite";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DownloadIcon from "./assets/bx_download.svg";
import styles from "./CropsDownload.module.scss";
import { Button } from "@mui/material";
import cropsDownload from "../../client-api/services/cropsDownload";
import { useContext, useState } from "react";
import { UIStoreContext } from "../../context";
import NoDataToShow from "../noDataToShow/NoDataToShow";

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", flex: 1 },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    cellClassName: "dateCell",
    align: "right",
  },
];

const VideoBatchDownload = () => {
  const crops = cropsDownload.GetCropsNamesByTime();
  const [selectedCrops, setSelectedCrops] = useState<string[]>([]);
  const detectionVideoMutations = cropsDownload.GetCropsByName();
  const uiStore = useContext(UIStoreContext);

  function openVideos(data: any) {
    for (let blob of data.blobs) window.open(blob);
  }

  function fetchVideos() {
    let formData = new FormData();
    formData.append("video_names", JSON.stringify(selectedCrops));
    formData.append("system_id", uiStore.getSelectedSystem());
    detectionVideoMutations.mutate(formData, { onSuccess: openVideos });
  }

  if (crops.isFetching || detectionVideoMutations.isLoading) {
    uiStore.setFilterApplyButtonDisabled(true);
  } else {
    uiStore.setFilterApplyButtonDisabled(false);
  }

  return uiStore.getSelectedSystem() === "" ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <NoDataToShow />
    </div>
  ) : (
    <div className={styles.layout}>
      <div className={styles.header}>
        <h4>Select Videos To Download</h4>
        <Button
          variant={"text"}
          className={styles.videoDownload}
          onClick={fetchVideos}
          disabled={selectedCrops.length === 0}
        >
          <img src={DownloadIcon} alt={"Download Icon"} />
          <span>
            {selectedCrops.length !== 0
              ? `Download ${selectedCrops.length} videos`
              : "No videos"}
          </span>
        </Button>
      </div>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaders": {
            display: "none",
          },
        }}
        rows={crops.data}
        columns={columns}
        rowsPerPageOptions={[]}
        checkboxSelection
        headerHeight={0}
        hideFooterSelectedRowCount={true}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          setSelectedCrops(
            crops.data
              .filter((row: any) => selectedIDs.has(row.id))
              .map((row: any) => row.name)
          );
        }}
      />
    </div>
  );
};

export default observer(VideoBatchDownload);
