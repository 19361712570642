import client from "../httpClient";
import { UserRole } from "../../common/userRole";

const userService = {
  GetUserRole: () => {
    return client.Get(
      "userRole",
      [],
      {},
      { cacheTime: -1, staleTime: -1, placeholderData: { role: "user" } }
    );
  },
};

export default userService;
