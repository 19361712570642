import styles from "./video.module.scss";
import CropsDownload from "../../components/cropsDownload/CropsDownload";

const Crops = () => {
  return (
    <div className={styles.layout}>
      <CropsDownload />;
    </div>
  );
};

export default Crops;
